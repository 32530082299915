import { getRequest, postRequest } from "api/api";
import { setElectricityState } from "app/features/electricity";
import {
  setBillers,
  setInitiatedTransaction,
  setInitiatedTranValues,
  setLoadBuyButton,
  setLoadToken,
  setShowTransReceipt,
  setSuccessTransactionDetails,
  setTotalAmount,
  setTransaction,
  setTransactions,
  setTransHistory,
  setTransLoading,
  setValidatedCustomer,
  setValidatedCustomerDetails,
  setValidatingCustomer,
} from "app/features/transactions";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { AxiosError } from "axios";
import { createContext, useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { SelectOptionType } from "types/shared";
import {
  InitiateTransValueType,
  TransactionType,
  ValidateCustomerType,
} from "types/transaction";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { trackMixpanelEvent } from "analytics";

interface ErrorFieldType {
  meterType: string;
  distributor: string;
  totalAmount: string;
}

interface TransactionContextType {
  fetchTransactions: () => Promise<void>;
  fetchTransHistory: () => Promise<void>;
  fetchTransaction: (value: string) => Promise<void>;
  validateCustomer: (value: ValidateCustomerType) => Promise<void>;
  fetchBillers: () => Promise<void>;
  handleInitiateTransaction: (value: InitiateTransValueType) => Promise<void>;
  // getTransactionStatus: (val: any) => Promise<void>;
  handleBillPayment: (val: any) => Promise<void>;
  handleVerifyTransaction: (val: any) => Promise<void>;
  calculateVat: (amount: number) => number;
  calculateFlutterCharge: (amount: number) => number;
  calculateElecCost: (amount: number) => number;
  validateFields: (val: InitiateTransValueType) => boolean;
  error: ErrorFieldType;
}

const TransactionContext = createContext({} as TransactionContextType);
interface Props {
  children: React.ReactNode;
}
const TransactionProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const {
    customerDetails,
    validatedCustomer,
    validateCustomerDetails,
    initiatedTransValues,
    requestedDetails,
  } = useAppSelector((state) => state.transactions);
  const [error, setError] = useState({} as ErrorFieldType);

  const validateFields = (value: InitiateTransValueType) => {
    const errors: any = {};
    if (value.distributor === "" || value.distributor === undefined) {
      errors.distributor = "Please select a distributor";
    }
    if (value.meterType === "" || value.distributor === undefined) {
      errors.meterType = "Please select a meter type";
    }
    if (!value.totalAmount) {
      errors.totalAmount = "Please enter a valid amount";
    }
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const calculateVat = (amount: number) => {
    const vat = amount * (7.5 / 100);
    return Math.round(vat * 100) / 100;
  };

  const calculateFlutterCharge = (amount: number) => {
    const charge = amount * (1.4 / 100);
    const newCharge = calculateVat(charge);
    const totalCharge = charge + newCharge;
    return Math.round(totalCharge * 100) / 100;
  };

  const calculateElecCost = (amount: number) => {
    const vat = calculateVat(amount);
    const flutter = calculateFlutterCharge(amount);
    const cost = amount - (vat + flutter + 100);
    return Math.round(cost * 100) / 100;
  };

  const fetchTransactions = useCallback(async () => {
    try {
      const { data } = await getRequest("transaction/history/all");
      dispatch(setTransactions(data));
    } catch (err) {
      const error = err as AxiosError;
      // console.error("all transactions", error);
    }
  }, []);
  const fetchTransHistory = useCallback(async () => {
    try {
      const { data } = await getRequest("transaction/history/analysis");
      dispatch(setTransHistory(data));
    } catch (err) {
      const error = err as AxiosError;
      console.error("transaction history", error.status);
    }
  }, []);
  const fetchTransaction = async (id: string) => {
    try {
      dispatch(setTransLoading(true));
      const { data } = await getRequest(`transaction/${id}`);
      dispatch(setTransaction(data));
    } catch (err) {
      const error = err as AxiosError;
      console.error("single transaction", error);
    } finally {
      dispatch(setTransLoading(false));
    }
  };
  const fetchBillers = useCallback(async () => {
    try {
      const { data } = await getRequest("transaction/billers");
      const newBillers: SelectOptionType[] = [];
      data?.map((item: any) => {
        return newBillers.push({ value: item.billerCode, label: item.name });
      });
      dispatch(setBillers(newBillers));
    } catch (err) {
      const error = err as AxiosError;
      // console.log(error);
    }
  }, []);
  const validateCustomer = async (value: ValidateCustomerType) => {
    dispatch(setValidatingCustomer(true));
    try {
      const { data, status } = await postRequest("transaction/validate", {
        itemCode: "",
        meterNumber: value.meterNumber,
        billerCode: value.billerCode,
      });
      if (status === 200) {
        dispatch(setValidatedCustomer(true));
        dispatch(setValidatedCustomerDetails(data));
      }
    } catch (err) {
      const error = err as AxiosError;
      // console.log("validata customer", error);
      toast.error(
        "Unable to verify the meter number at this time. Please, try again in a few minutes"
      );
    } finally {
      dispatch(setValidatingCustomer(false));
    }
  };
  const handleInitiateTransaction = async (value: InitiateTransValueType) => {
    if (validatedCustomer) {
      dispatch(setLoadBuyButton(true));
      dispatch(setLoadToken(true));
      try {
        const { data, status } = await postRequest(
          "transaction/initiate",
          value
        );
        dispatch(setInitiatedTransaction(data));
        dispatch(setTotalAmount(value.totalAmount));
        if (status === 201) {
          const flutterwaveConfig = {
            public_key: process.env.REACT_APP_FLW_PUBLIC_ID,
            amount: value.totalAmount,
            tx_ref: data.referenceNumber,
            currency: "NGN",
            payment_options: "card,ussd,banktransfer,nqr,barter",
            customer: {
              email: value.email,
              phone_number: value.phoneNumber,
              name: customerDetails.name,
            },
            customizations: {
              title: "PAWA360",
              description: "Payment for Light",
              logo: "assets/icons/logo.svg",
            },
            callback: (val: any) => {
              if (val.status === "successful" || val.status === "completed") {
                trackMixpanelEvent("Successful Payment", {
                  amount: value.totalAmount,
                  email: value.email,
                  phone: value.phoneNumber,
                  meterNumber: value.meterNumber,
                  meterType: value.meterType,
                  distributor: value.distributor!,
                });
                handleVerifyTransaction({ data: val, id: data.id });
                // closePaymentModal();
                toast(
                  "Please wait. Your token will be displayed shortly. It will also be sent to the email address you provided.", {duration: 8000}
                );
              }
              setTimeout(() => {
                try {
                  closePaymentModal();
                } catch (error) {
                  toast(
                    "Please, close the pop-up window if it does not close automatically."
                  );
                }
              }, 3000);
            },
            onclose: (val: any) => {
              dispatch(
                setInitiatedTranValues({
                  meterType: "",
                  distributor: "",
                  totalAmount: 0,
                  meterNumber: "",
                  email: "",
                  phoneNumber: "",
                } as InitiateTransValueType)
              );
              dispatch(setTransaction({} as TransactionType));
              dispatch(setValidatedCustomer(false));
              dispatch(setElectricityState("buy"));
            
              if (requestedDetails) {
                window.location.reload();
              }
            },
          };

          if (typeof (window as any).FlutterwaveCheckout === "undefined") {
            console.error("Flutterwave script not loaded");
            return;
          }
          (window as any).FlutterwaveCheckout(flutterwaveConfig);
        }
      } catch (err) {
        const error = err as AxiosError;
        toast.error(error.message);
        dispatch(setLoadToken(false));
      } finally {
        dispatch(setLoadBuyButton(false));
      }
    } else {
      toast.error("Please, enter a valid meter number");
    }
  };
  const handleVerifyTransaction = async (val: any) => {
    dispatch(setValidatedCustomer(false));
    dispatch(setElectricityState("buy"));
    dispatch(setShowTransReceipt(true));
    dispatch(setLoadToken(true));
    const value = {
      transactionReference: val.data.tx_ref,
      flutterwaveTransactionId: parseInt(val.data.transaction_id),
      status: val.data.status === "completed" ? "successful" : val.data.status,
      amount: val.data.amount,
      currency: val.data.currency,
    };
    try {
      const { data, status } = await postRequest("transaction/verify", value);
      // console.log(data);
      if (status === 200) {
        // setLoadToken(true);
        handleBillPayment(val);
      }
    } catch (err) {
      // console.log("validate trasaction", err);
      dispatch(setShowTransReceipt(false));
      toast.error("We are unable to verify this transaction");
    }
  };
  // const getTransactionStatus = async (val: any) => {
  //   try {
  //     const { data } = await getRequest(`transaction/${val.id}/status`);
  //     console.log(data === "Success");
  //     if (data === "Success") {
  //       handleBillPayment({ ref: val });
  //     }
  //     handleBillPayment({ ref: val });
  //   } catch (err) {
  //     console.log("trasaction status", err);
  //   }
  // };
  const handleBillPayment = async (val: any) => {
    try {
      const { data, status } = await postRequest("transaction/bill/payment", {
        billerCode: validateCustomerDetails.billerCode,
        amount: calculateElecCost(parseInt(val.data.amount)),
        reference: val.data.tx_ref,
        itemCode: customerDetails.itemCode,
        meterNumber: customerDetails.meterNumber,
        email: initiatedTransValues.email,
        customerName: customerDetails.name,
        customerAddress: customerDetails.address,
      });
      if (status === 200) {
        dispatch(setSuccessTransactionDetails(data));
        dispatch(setLoadToken(false));
        fetchTransHistory();
        fetchTransactions();
      }
    } catch (err) {
      dispatch(setShowTransReceipt(false));
      toast.error("Something went wrong, Please contact Support");
      dispatch(
        setInitiatedTranValues({
          meterType: "",
          distributor: "",
          totalAmount: 0,
          meterNumber: "",
          email: "",
          phoneNumber: "",
        } as InitiateTransValueType)
      );
      dispatch(setTransaction({} as TransactionType));
    } finally {
      dispatch(setValidatedCustomer(false));
    }
  };
  useEffect(() => {
    fetchBillers();
  }, [fetchBillers]);
  return (
    <TransactionContext.Provider
      value={{
        fetchTransactions,
        fetchTransHistory,
        fetchTransaction,
        validateCustomer,
        fetchBillers,
        handleInitiateTransaction,
        handleBillPayment,
        handleVerifyTransaction,
        calculateVat,
        calculateFlutterCharge,
        calculateElecCost,
        error,
        validateFields,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

export { TransactionProvider, TransactionContext };
